@import "~bootstrap/scss/bootstrap";
@import "./section.scss";
@import "./login.scss";
/*@import "./dashboardadmin.scss";*/

$sidebar_active_color: #333;
@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file + ".ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
}

@include font("Ubuntu-Bold", "../fonts/Ubuntu-Bold");
@include font("Ubuntu-BoldItalic", "../fonts/Ubuntu-BoldItalic");
@include font("Ubuntu-Light", "../fonts/Ubuntu-Light");
@include font("Ubuntu-LightItalic", "../fonts/Ubuntu-LightItalic");
@include font("Ubuntu-Medium", "../fonts/Ubuntu-Medium");
@include font("Ubuntu-MediumItalic", "../fonts/Ubuntu-MediumItalic");
@include font("Ubuntu-Regular", "../fonts/Ubuntu-Regular");
@include font("Ubuntu-RegularItalic", "../fonts/Ubuntu-RegularItalic");

body {
  font-family: Ubuntu-Regular, sans-serif !important;
}

/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #525965;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #525965;
}
::-webkit-scrollbar-thumb:active {
  background: #525965;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.langbtn {
  max-width: 30px;
  display: flex;
  background: #fafafa;
  background: -moz-linear-gradient(top, #fafafa 59%, #e0e0e0 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(59%, #fafafa),
    color-stop(100%, #e0e0e0)
  );
  background: -webkit-linear-gradient(top, #fafafa 59%, #e0e0e0 100%);
  background: -ms-linear-gradient(top, #fafafa 59%, #e0e0e0 100%);
  background: linear-gradient(to bottom, #fafafa 59%, #e0e0e0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#e0e0e0',GradientType=0 );
  border-radius: 50%;
  z-index: 10000;
  border: 0px;
}

div.mod-languages ul.languagelist {
  margin-top: -3px;
  border: none;
  background-color: #f2f2f2;
  z-index: 10000;
  transition: 0.3s all;
  border-radius: 0px 0px 3px 3px;
}

div.mod-languages ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.languagelist.uoe--language-list > li#actives {
  background-attachment: scroll;
  background-clip: border-box;
  background-color: #e0e0e0;
  background-image: none;
  background-origin: padding-box;
  background-position: 0 0;
  background-repeat: repeat;
  background-size: auto auto;
  text-decoration-style: solid;
  text-decoration-color: -moz-use-text-color !important;
  text-decoration-line: none !important;
  text-decoration-style: solid !important;
}

.languagelist.uoe--language-list > li {
  padding: 0px;
  margin: 0px;
}
.languages {
  float: left;
  margin-left: 10px;
  position: relative;
  width: auto;
}
.languages .glyphicon.glyphicon-globe {
  background: url(../images/fr.png) no-repeat center center;
  width: 30px;
  overflow: hidden;
  opacity: 0.8;
  height: 30px;
  background-size: contain;
}

.dropdown-menu.languagelist.uoe--language-list > li > a:link,
.dropdown-menu.languagelist.uoe--language-list > li > a:visited {
  display: block;
  padding: 4px;
  border-top: 1px solid #e0e0e0;
  font-size: 12px;
  padding: 9px;
  position: relative;
  line-height: 14px;
  box-shadow: none;
  color: $sidebar_active_color;
}
.dropdown-menu.languagelist.uoe--language-list > li#actives a {
  font-weight: bold;
  background-color: #e0e0e0;
}
div.mod-languages a {
  text-decoration: none;
  background: transparent;
  transition: all 0.1s ease-in-out;
}
.dropdown-menu.languagelist.uoe--language-list > li > a:hover,
.dropdown-menu.languagelist.uoe--language-list > li > a:active {
  font-weight: bold;
}

.langbtn:focus {
  outline: none !important;
}
.mod-languages button {
  transition: 0.3s all;
}
.mod-languages.show button {
  border-radius: 3px;
  background-color: #f2f2f2;
  transition: 0.3s all;
}
.mod-languages ul li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 5px !important;
}
.mod-languages ul li img {
  height: 16px;
}

.popup-over .popup-title {
  display: inline-block;
  touch-action: manipulation;
  color: rgba(255, 255, 255, 0.95);
  text-decoration: none;
  -webkit-transition: all 0.15s linear;
  transition: all 0.15s linear;
}

.popup-over .popup-title:hover {
  color: rgba(255, 255, 255, 0.65);
}

@media (min-width: 768px) {
  .float-md-right {
    float: right !important;
  }
}

.popup-over {
  position: relative;
  color: #fff;
  float: right;
  padding: 15px 0 15px 20px;
  background: transparent;
  min-height: 100%;
  font-size: 14px;
}

.popup-over .popup-content {
  padding: 0.625rem;
  margin: 0;
  position: absolute;
  top: 97%;
  background: #ffffff;
  border: 1px solid #eaeaea;
  min-width: 150px;
  z-index: 9999;
  display: none;
  right: inherit;
  left: inherit;
  border-radius: 0;
  text-align: left;
}

#leo_block_top .language-selector span,
#leo_block_top .currency-selector span {
  font-size: 12px;
  color: #000000;
}

#leo_block_top ul {
  border: 1px solid #eaeaea;
  margin-top: 5px;
  padding: 0;
}

.popup-over .popup-content li.current {
  color: #f23926;
}

#leo_block_top .language-selector ul li,
#leo_block_top .currency-selector ul li {
  display: inline-block;
  padding: 5px;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 3px 1.5rem;
  clear: both;
  font-weight: normal;
  color: #373a3c;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
}

.popup-over .popup-content a {
  color: #666666;
  padding: 0px;
  font-size: 13px;
}

.popup-over .popup-content li.current a {
  color: #f23926;
}

img {
  vertical-align: middle;
}

.popup-over .popup-content li.current a img {
  width: 18px;
  height: 13px;
  padding: 1px;
  border: 1px solid #f23926;
}

#leo_block_top .language-selector span,
#leo_block_top .currency-selector span {
  font-size: 12px;
  color: #000000;
}

.popup-over:hover {
  cursor: pointer;
}

.bootstrap-select > .dropdown-toggle {
  border: 1px solid #ced4da !important;
}

.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: #fff;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: #fff;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  color: #fff;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.recapitulatif td {
  padding: 0.4rem 0.4rem;
}
.recapitulatif .row100 td:first-child {
  font-family: Ubuntu-Medium;
  font-size: 100%;
}

#search_btn:focus {
  outline: 0;
  box-shadow: none;
}

.box-shadow {
  box-shadow: 0 1px 1px 0 rgba(60, 75, 100, 0.14),
    0 2px 1px -1px rgba(60, 75, 100, 0.12), 0 1px 3px 0 rgba(60, 75, 100, 0.2);
}

.has-error input,
.has-error select {
  border-color: #dc3545 !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
}

.has-error .invalid-feedback {
  display: block !important;
}
.hover a i {
  margin-right: 0.3rem;
}
.form-control:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #6772e5 !important;
  outline: 0 !important;
  box-shadow: inset 0 0 0 0 rgba(34, 36, 38, 0.35) !important;
}
.navbar-transparent .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.95);
}
#search_btn:focus {
  outline: 0;
  box-shadow: none;
}

#admin_table_filter,
#perso_table_filter,
#type_op_table_filter,
#type_account_table_filter,
#perso_table_length,
#type_op_table_length,
#type_account_table_length {
  display: none;
}

#admin_table_length label select {
  font-size: 11px;
  font-weight: 400;
  max-width: 70px;
  margin-top: 5px;
}

#admin_table_length label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#admin_table_paginate,
#type_op_table_paginate,
#type_account_table_paginate,
#perso_table_paginate {
  float: right;
}
#admin_table_info,
#perso_table_info,
#type_account_table_info,
#type_op_table_info,
#admin_table_paginate {
  font-size: 80%;
}
.action_class {
  min-width: 146px;
}
.date_class {
  min-width: 117px;
}
.solde_class {
  min-width: 70px;
  font-weight: bold;
}

.chiller-theme .span3 .sidebar-menu ul li.active > a {
  color: #333;
}
.chiller-theme .span3 .sidebar-dropdown .sidebar-submenu li.active > a:before {
  color: #16c7ff;
  text-shadow: 0px 0px 10px rgba(22, 199, 255, 0.5);
}
.megamenu {
  position: static;
}

.megamenu .dropdown-menu {
  background: none;
  border: none;
  width: 100%;
}
a.link-text {
  color: #212529 !important;
}
/* .span3 .sidebar-menu ul li a {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
} */
.label-detail-row {
  font-family: Ubuntu-Medium;
  font-size: 100%;
}

.label-detail-row,
.value-detail-row {
  padding: 0.4rem 0.4rem !important;
}

.modal-confirm {
  color: #636363;
  width: 400px;
}
.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 14px;
}
.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}
.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -10px;
}
.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -2px;
}
.modal-confirm .modal-body {
  color: #999;
}
.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px 15px 25px;
}
.modal-confirm .modal-footer a {
  color: #999;
}
.modal-confirm .icon-box {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
  border: 3px solid #f15e5e;
}
.modal-confirm .icon-box2 {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
  border: 3px solid #28a745;
}
.modal-confirm .icon-box i {
  color: #f15e5e;
  font-size: 46px;
  display: inline-block;
  margin-top: 13px;
}

.modal-confirm .icon-box2 i {
  color: #28a745;
  font-size: 46px;
  display: inline-block;
  margin-top: 13px;
}

.modal-confirm .btn {
  color: #fff;
  border-radius: 4px;
  background: #60c7c1;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 120px;
  border: none;
  min-height: 40px;
  border-radius: 3px;
  margin: 0 5px;
  outline: none !important;
}
.modal-confirm .btn-info {
  background: #c1c1c1;
}
.modal-confirm .btn-info:hover,
.modal-confirm .btn-info:focus {
  background: #a8a8a8;
}
.modal-confirm .btn-danger {
  background: #f15e5e;
}
.modal-confirm .btn-danger:hover,
.modal-confirm .btn-danger:focus {
  background: #ee3535;
}
.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}
.SKWP2e td {
  padding: 0;
}
@media screen and (max-height: 450px) and (min-width: 460px) {
  .PaQdxb {
    height: 27px;
    line-height: 26px;
    margin: 2px;
  }
}
.PaQdxb {
  cursor: pointer;
  font-family: arial, sans-serif;
  text-align: center;
  line-height: 34px;
  margin: 4px;
  box-sizing: border-box;
  position: relative;
  border-radius: 4px;
  -webkit-touch-action: manipulation;
  touch-action: manipulation;
}
.A2W7l {
  font-size: 14px;
}
.MEdqYd,
.feqqG {
  background: #dfe1e5;
  color: #202124;
  border: 1px solid #dfe1e5;
  border-radius: 4px;
  box-sizing: border-box;
}
.XRsWPe {
  outline: none;
}
@media screen and (max-height: 450px) and (min-width: 460px) {
  .PaQdxb {
    height: 27px;
    line-height: 26px;
    margin: 2px;
  }
}
.PaQdxb {
  cursor: pointer;
  font-family: arial, sans-serif;
  text-align: center;
  line-height: 34px;
  margin: 4px;
  box-sizing: border-box;
  position: relative;
  border-radius: 4px;
  -webkit-touch-action: manipulation;
  touch-action: manipulation;
}
.A2W7l {
  font-size: 14px;
}
.HOoTuc,
.ElumCf {
  border-collapse: collapse;
  table-layout: fixed;
}
.AOvabd,
.dxp44d {
  background: #f1f3f4;
  color: #202124;
  border: 1px solid #f1f3f4;
  border-radius: 4px;
}
.AOvabd:hover,
.dxp44d:hover {
  background: #e8eaeb;
  border-color: #e8eaeb;
}
.AOvabd:focus:hover,
.dxp44d:focus:hover {
  background: #e8eaeb;
}
.AOvabd:focus,
.dxp44d:focus {
  border-color: #bdc1c6;
}

.recapitulatif td {
  padding: 0.4rem 0.4rem;
}

.row100 td {
  font-size: 90%;
}

.row100 td:first-child {
  font-family: Ubuntu-Medium;
  font-size: 100%;
}
.span9 {
  min-height: 60vh;
}

.state_class {
  width: 150px;
}

.offer-desc {
  border-top: 2px solid #007bff;
  border-bottom: 2px solid #007bff;
  padding-top: 5px;
  min-height: 135px;
}

.offer-text {
  color: deepskyblue;
  font-size: 90%;
  font-style: italic;
  text-align: left;
}

#recapitulatif_table_paginate,
#recapitulatif_table_filter {
  float: right;
}

#recapitulatif_table_info,
#recapitulatif_table,
#recapitulatif_table_paginate,
#recapitulatif_table_filter,
#recapitulatif_table_length {
  font-size: 80%;
}

.solde_class {
  min-width: 150px;
}

.dasboard-header {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #172b4d !important;
}
#navbar-main {
  position: fixed;
}
#search_btn:focus {
  outline: 0;
  box-shadow: none;
}
#client_table_filter,
#client_table_length {
  display: none;
}

#client_table_paginate {
  float: right;
}
#client_table_info,
#client_table_paginate {
  font-size: 80%;
}

@media (min-width: 576px) {
  .product-list-item .card-deck > .card {
    flex: 1 0 25%;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 10px;
  }
}

.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
