@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

@import "headroom.scss";
@import "navbar.scss";

.navbar-brand {
  color: #fff !important;
  font-weight: 500;
}
.pb-lg,
.py-lg {
  padding-bottom: 1rem !important;
}

.pt-lg,
.py-lg {
  padding-top: 4rem !important;
}

.btnn {
  position: relative;
  width: auto;
  cursor: pointer;
  font-size: 14px;
  margin: 0px;
  border-radius: 4px;
  transition: all 80ms ease-in-out 0s;
  padding: 12px 16px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  border-image: initial;
  white-space: nowrap;
  text-transform: none;
  line-height: 1.15;
  text-decoration: none;
}

.btnn:hover {
  text-decoration: none;
}
.sigin_btn {
  color: rgb(255, 255, 255);
  background-color: transparent;
  margin-left: 16px;
}
.sigin_btn:hover {
  color: rgb(38, 53, 67);
  background-color: rgb(255, 255, 255);
}

.login_btn {
  color: rgb(38, 53, 67);
  background-color: rgb(255, 255, 255);
}

.login_btn:hover {
  color: rgb(255, 255, 255);
  background-color: transparent;
}

.titre_accroche {
  color: #fff;
}
.balance-wrapper .titre_accroche {
  font-weight: 700;
}
.balance-wrapper .lead.text-white,
.balance-wrapper .titre_accroche {
  margin-bottom: 0px;
}
.lead.text-white {
  font-family: Ubuntu-Light;
}
.googleplay {
  background: url(../images/googleplay.png);
  width: 160px;
  height: 60px;
  display: block;
  margin: 10px;
  background-size: contain;
  background-repeat: no-repeat;
}

.applestore {
  background: url(../images/applestore.png);
  width: 160px;
  height: 60px;
  display: block;
  margin: 10px;
  background-size: contain;
  background-repeat: no-repeat;
}

.features-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1180px;
  flex-shrink: 0;
  margin: 0px auto;
  padding: 24px;
}

.features-content {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 30px;
  flex: 1 1 auto;
}

.features-title {
  font-size: 40px;
  font-weight: 500;
  color: rgb(15, 50, 86);
  text-align: center;
  margin: 0px 0px 10px;
}

.features-subtitle {
  color: rgb(144, 161, 184);
  text-align: center;
}

.features-slide-group {
  display: flex;
  flex-direction: row;
  width: 100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 60px;
}

.features-description {
  max-width: 395px;
  position: relative;
}

.features-slider {
  max-width: 700px;
  position: relative;
}

.slide-image {
  max-width: 700px;
}
.slide-item {
  position: relative;
  max-height: 330px;
  overflow: hidden;
}

.text-block {
  position: absolute;
  bottom: 0px;
  width: 100%;
  opacity: 0.6;
  background-color: black;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.text-block p {
  margin: 0px;
}

.slider-btn {
  opacity: 0.5;
  position: absolute;
  background: black;
  min-height: 10px;
  z-index: 999;
  color: #fff;
  padding: 15px 5px;
  top: 50%;
  cursor: pointer;
  margin-top: -31px;
  transition: 0.3s all;
}
.slider-btn:hover {
  opacity: 1;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

.features-description h3 {
  margin: 15px auto;
  text-align: center;
}

.features-description p {
  text-align: justify;
}
.features-description a {
  max-width: 150px;
  margin-top: 30px;
}

.avantage-header {
  position: absolute;
}
.avantage-header img {
  max-width: 50px;
  float: left;
}

.avantage-header h5 {
  position: absolute;
  left: 75px;
  top: 10px;
}

.stats-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1180px;
  flex-shrink: 0;
  margin: 0px auto;
  padding: 24px;
}

.stats-content {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  color: white;
  flex: 1 1 auto;
  margin: 40px 0px;
}

.stats-item {
  text-align: center;
  flex: 1 1 0%;
}

.stats-title {
  line-height: 48px;
  font-size: 56px;
  font-weight: 500;
  margin: 0px 0px 12px;
}

.stats-subtitle {
  line-height: 24px;
  font-size: 16px;
  opacity: 0.7;
}

.footer {
  min-height: 60px;
  padding: 20px 0px 0px 0px;
  background-color: #f5f5f5;
  display: flex;
}

@mixin modal-fullscreen() {
  padding: 0 !important;

  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .modal-content {
    height: auto;
    min-height: 100%;
    border: 0 none;
    border-radius: 0;
  }
}

@media (max-width: 767px) {
  .modal-fullscreen-xs-down {
    @include modal-fullscreen();
  }
}

@media (max-width: 991px) {
  .modal-fullscreen-sm-down {
    @include modal-fullscreen();
  }
}

@media (max-width: 1199px) {
  .modal-fullscreen-md-down {
    @include modal-fullscreen();
  }
}

.modal-fullscreen {
  @include modal-fullscreen();
}

.close-modal-btn {
  font-size: 100px;
  opacity: 0.3;
  font-weight: 300;
  color: #444;
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  right: 30px;
  top: -45px;
  z-index: 9999;
  transition: 0.3s all;
  display: none;
  position: fixed;
}

.close-modal-btn:hover {
  opacity: 1;
  text-decoration: none;
  color: #444;
}

.dasboard-header {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #172b4d !important;
}

.filter-block {
  background-color: #fff;
  border-color: #c3e6cb;
  position: relative;
  width: 100%;
  padding: 0.75rem 0rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin-left: 0;
  margin-right: 0;
}
.filter-block div {
  padding: 0;
}
.display-style {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.display-style a.active {
  color: #16c7ff;
}
.display-style a {
  margin-left: 15px;
  color: #777;
  text-decoration: none;
}
.display-style span {
  margin-left: 25px;
}
.trier-block {
  width: 40%;
  float: right;
  display: flex;
  flex-direction: row;
  float: right;
}
.form-control2 {
  display: block;
  width: 60%;
  padding: 0.2rem 0.5rem;
  font-size: 80%;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.transaction-list-item ul li {
  cursor: pointer;
}

.transaction-list-day {
  font-family: Ubuntu-Light;
  font-size: 80%;
  color: #888;
  font-weight: 400;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.transaction-list-wrapper ul.list-group-flush li.list-group-item {
  padding: 0.7rem 0.2rem;
}
.transaction-right h6 {
  font-family: Ubuntu-Bold;
}
// .transaction-text h6 span {
//   //font-family: Ubuntu-LightItalic;
// }
.plus-btn {
  float: right;
  color: #00d0ba;
  font-weight: 700;
  padding: 0;
}

.plus-btn:hover {
  color: #05a292;
}
.account-name,
.message {
  font-family: Ubuntu-Medium;
}
.active2 {
  color: #00d0ba;
}
.display-style a:hover {
  color: #16c7ff;
}
.navbar-nav .nav-item a:hover,
.nav .nav-item a:hover {
  color: #00d0ba !important;
}
.nav .nav-item a {
  color: #0f3256;
  transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
}

@media screen {
  #printSection {
    display: none;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  #printSection,
  #printSection * {
    visibility: visible;
  }
  #printSection .btn {
    visibility: hidden;
  }
  #printSection {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.compte-wrapper {
  width: 100%;
}
