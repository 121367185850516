.wrapper {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
}

.auth {
  display: flex;
  flex-flow: column;
  width: 100vw;
  min-height: 100vh;
  background-color: #6772e5;
  justify-content: center;
  align-content: center;
}

.auth .auth-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 100vh;
}

@media screen and (min-width: 900px) {
  .auth .auth-container .auth-footer,
  .auth .auth-container .auth-header {
    margin: 1rem 0;
  }
}

.auth .auth-container .auth-footer,
.auth .auth-container .auth-header {
  text-align: center;
  position: relative;
}

.auth .auth-container .auth-wallet-logo {
  height: 2.33333333rem;
  margin: 3rem 0 2rem;
  //background: url(/3f3f709….svg) 50%/contain no-repeat;
}

@media screen and (min-width: 900px) {
  .auth .auth-container .auth-wallet-logo {
    height: 3.5rem;
    margin: 0;
  }
}

.auth .auth-container .auth-content {
  display: flex;
  margin: 0 auto;
  border-radius: 11px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  width: calc(100% - 2rem);
}

@media screen and (min-width: 900px) {
  .auth .auth-container .auth-content.large {
    width: 40rem;
  }
}

.login-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 650px) {
  .login-content {
    flex-direction: row;
  }
}

.login-content .login-userpass {
  width: 100%;
  padding: 1rem 2rem;
  border-radius: 11px 0 0 11px;
}

@media screen and (min-width: 650px) {
  .login-content .login-userpass {
    width: 100%;
  }
}

@media screen and (min-width: 900px) {
  .login-content .login-userpass {
    padding: 2.8rem;
  }
}

.auth .auth-container .auth-content .auth-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 1rem;
}

@media screen and (min-width: 650px) {
  .auth .auth-container .auth-content .auth-content-header {
    padding: 0 0 1.5rem;
  }
}

.auth .auth-container .auth-content .auth-content-header h1 {
  font-size: 2.2em;
  font-weight: 500;
  text-align: left;
  color: #3c5273;
  margin: 0;
}

.ui.form {
  position: relative;
  max-width: 100%;
}

.ui.form {
  font-size: 1rem;
}

.ui.form .field {
  clear: both;
  margin: 0 0 1em;
}

.ui.form .field > label {
  display: block;
  margin: 0 0 0.28571429rem;
  color: #354052;
  font-size: 0.92857143em;
  font-weight: 400;
  text-transform: none;
}

.ui.input {
  position: relative;
  font-style: normal;
  display: flex;
  flex-direction: column;
  color: #354052;
}

.ui.input {
  font-size: 1em;
}

.ui.input,
.ui.input input,
.ui.input input:not([type]),
.ui.input input[type="date"],
.ui.input input[type="datetime-local"],
.ui.input input[type="email"],
.ui.input input[type="file"],
.ui.input input[type="number"],
.ui.input input[type="password"],
.ui.input input[type="search"],
.ui.input input[type="tel"],
.ui.input input[type="text"],
.ui.input input[type="time"],
.ui.input input[type="url"],
.ui.label {
  font-weight: 400;
}

.ui.form .field .ui.input,
.ui.form .fields .field .ui.input,
.ui.form .wide.field .ui.input {
  width: 100%;
}

button,
input {
  overflow: visible;
}

.ui.input > input {
  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: Roboto, sans-serif;
  padding: 0.67857143em 1em;
  background: #fff;
  border: 1px solid #dedede;
  color: #354052;
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
}
.form-compose {
  border: 1px solid #dedede;
  border-radius: 0.28571429rem;
  background-color: #dedede;
  input[type="tel"] {
    border: none !important;
    background-color: transparent !important;
  }
}

.ui.form .field .ui.input input,
.ui.form .fields .field .ui.input input {
  width: auto;
}

@media screen and (min-width: 650px) {
  .auth .auth-container .auth-content .auth-form input {
    height: 3.4rem;
  }
}

.auth .auth-container .auth-content .auth-form input {
  height: 2.5rem;
}
.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="file"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="text"],
.ui.form input[type="time"],
.ui.form input[type="url"] {
  font-family: Roboto, sans-serif;
  margin: 0;
  outline: none;
  -webkit-appearance: none;
  // tap-highlight-color: hsla(0,0%,100%,0);
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  font-size: 1em;
  background: #fff;
  border: 1px solid #dedede;
  color: #354052;
  border-radius: 0.28571429rem;
  box-shadow: inset 0 0 0 0 transparent;
  transition: color 0.1s ease, border-color 0.1s ease;
}
.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="file"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="text"],
.ui.form input[type="time"],
.ui.form input[type="url"],
.ui.form textarea {
  width: 100%;
  vertical-align: top;
}

button,
input {
  overflow: visible;
}

[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
}

.ui.button {
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: none;
  border: none;
  vertical-align: baseline;
  background: #e0e1e2 none;
  color: rgba(0, 0, 0, 0.6);
  font-family: Roboto, sans-serif;
  margin: 0 0.25em 0 0;
  padding: 0.78571429em 1.5em;
  text-transform: none;
  text-shadow: none;
  font-weight: 400;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 0.28571429rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  will-change: "";
  -webkit-tap-highlight-color: transparent;
}

.ui.button,
.ui.buttons .button,
.ui.buttons .or {
  font-size: 1rem;
}

.ui.button,
.ui.button:hover {
  box-shadow: inset 0 0 0 1px transparent, inset 0 0 0 0 #dedede;
}

.ui.active.button:active,
.ui.button:active {
  background-color: #babbbc;
  background-image: "";
  color: rgba(0, 0, 0, 0.9);
  box-shadow: inset 0 0 0 1px transparent, none;
}

.ui.button,
.ui.button:hover {
  box-shadow: inset 0 0 0 1px transparent, inset 0 0 0 0 #dedede;
}

.ui.button:hover {
  background-color: #cacbcd;
  background-image: none;
  color: rgba(0, 0, 0, 0.8);
}

.ui.active.button:active,
.ui.button:active {
  background-color: #babbbc;
  background-image: "";
  color: rgba(0, 0, 0, 0.9);
  box-shadow: inset 0 0 0 1px transparent, none;
}

.ui.circular.button {
  border-radius: 0.3em;
}

.ui.fluid.button,
.ui.fluid.buttons {
  width: 100%;
}

.ui.fluid.button {
  display: block;
}

.ui.secondary.button,
.ui.secondary.buttons .button {
  background-color: #6772e5;
  color: #fff;
  text-shadow: none;
  background-image: none;
}

.ui.secondary.button {
  box-shadow: inset 0 0 0 0 #dedede;
}

.ui.secondary.button:hover,
.ui.secondary.buttons .button:hover {
  background-color: #7795f8;
  color: #fff;
  text-shadow: none;
}

.ui.secondary.button:active,
.ui.secondary.buttons .button:active {
  background-color: #7795f8;
  color: #fff;
  text-shadow: none;
}

.ui.secondary.button:active,
.ui.secondary.buttons .button:active {
  background-color: #7795f8;
  color: #fff;
  text-shadow: none;
}

.auth a {
  text-decoration: underline;
}

.login-content .login-userpass .login-lost-password-link {
  margin-top: 1rem;
  display: block;
  text-align: center;
}

.login-content .login-oauth {
  width: 100%;
  padding: 1rem 2rem;
  border-radius: 0 0 11px 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #eeeff0;
  position: relative;
}

@media screen and (min-width: 650px) {
  .login-content .login-oauth {
    border-radius: 0 11px 11px 0;
    width: 50%;
  }
}

@media screen and (min-width: 900px) {
  .login-content .login-oauth {
    padding: 2.8rem;
  }
}

.login-content .login-oauth .oauth-button {
  margin-bottom: 1rem;
  background-position: 0.5rem;
  background-repeat: no-repeat;
  background-size: 2.2rem;
  font-weight: 400 !important;
  font-size: 1rem !important;
  padding: 1rem 0 !important;
}

@media screen and (min-width: 650px) {
  .login-content .login-oauth .oauth-button {
    margin-bottom: 2rem;
  }
}

.ui.facebook.button {
  background-color: #3b5998;
  color: #fff;
  text-shadow: none;
  background-image: none;
  box-shadow: inset 0 0 0 0 #dedede;
}

@media screen and (min-width: 650px) {
  .login-content .login-oauth .oauth-button:first-child {
    margin-top: 0rem;
  }
}

.login-content .login-oauth .oauth-button.facebook {
  background-image: url(../images/facebook.png) !important;
}

.ui.blue.button,
.ui.blue.buttons .button {
  background-color: #0076ff;
  color: #fff;
  text-shadow: none;
  background-image: none;
}

.ui.blue.button {
  box-shadow: inset 0 0 0 0 #dedede;
}

.login-content .login-oauth .oauth-button.google {
  background-image: url(../images/google.png) !important;
  background-color: #4285f4 !important;
}

.signin-consent {
  margin: 10px 10px 10px 0px;
}

.login-content .login-oauth .signin-consent {
  margin-bottom: 1rem;
}

.ui.checkbox {
  position: relative;
  display: inline-block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: none;
  vertical-align: baseline;
  font-style: normal;
  min-height: 17px;
  font-size: 1rem;
  line-height: 17px;
  min-width: 17px;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

.ui.checkbox input[type="checkbox"],
.ui.checkbox input[type="radio"] {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0 !important;
  outline: none;
  z-index: 3;
  width: 17px;
  height: 17px;
}

.ui.checkbox2 {
  display: flex;
}
.ui.checkbox2 label {
  cursor: auto;
  position: relative;
  display: block;
  padding-left: 0.1rem;
  outline: none;
  font-size: 1em;
}

.ui.checkbox2 + label,
.ui.checkbox2 label {
  color: #354052;
  transition: color 0.1s ease;
}

.ui.checkbox .box:after,
.ui.checkbox .box:before,
.ui.checkbox label:after,
.ui.checkbox label:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 17px;
  height: 17px;
  transition: border 0.1s ease, opacity 0.1s ease, transform 0.1s ease,
    box-shadow 0.1s ease;
}

.auth .auth-container .auth-footer,
.auth .auth-container .auth-header {
  text-align: center;
  position: relative;
}

.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before {
  background: #fff;
  border-color: rgba(34, 36, 38, 0.35);
}

.ui.checkbox input:focus ~ .box:before,
.ui.checkbox input:focus ~ label:before {
  background: #fff;
  border-color: #96c8da;
}

.ui.checkbox input:checked:focus ~ .box:before,
.ui.checkbox input:checked:focus ~ label:before,
.ui.checkbox input:not([type="radio"]):indeterminate:focus ~ .box:before,
.ui.checkbox input:not([type="radio"]):indeterminate:focus ~ label:before {
  background: #fff;
  border-color: #96c8da;
}

.ui.checkbox .box:hover:before,
.ui.checkbox label:hover:before {
  background: #fff;
  border-color: rgba(34, 36, 38, 0.35);
}

.ui.checkbox .box:active:before,
.ui.checkbox label:active:before {
  background: #f9fafb;
  border-color: rgba(34, 36, 38, 0.35);
}

.ui.checkbox .box:before,
.ui.checkbox label:before {
  content: "";
  background: #fff;
  border-radius: 0.21428571rem;
  border: 1px solid #d4d4d5;
}

.ui.checkbox .box:after,
.ui.checkbox .box:before,
.ui.checkbox label:after,
.ui.checkbox label:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 17px;
  height: 17px;
  transition: border 0.1s ease, opacity 0.1s ease, transform 0.1s ease,
    box-shadow 0.1s ease;
}

.ui.checkbox .box:hover:before,
.ui.checkbox label:hover:before {
  background: #fff;
  border-color: rgba(34, 36, 38, 0.35);
}

.ui.checkbox input:focus ~ .box:before,
.ui.checkbox input:focus ~ label:before {
  background: #fff;
  border-color: #96c8da;
}

.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before {
  background: #fff;
  border-color: rgba(34, 36, 38, 0.35);
}

.ui.checkbox input:checked:focus ~ .box:before,
.ui.checkbox input:checked:focus ~ label:before,
.ui.checkbox input:not([type="radio"]):indeterminate:focus ~ .box:before,
.ui.checkbox input:not([type="radio"]):indeterminate:focus ~ label:before {
  background: #fff;
  border-color: #96c8da;
}
@media screen and (min-width: 900px) {
  .auth .auth-container .auth-footer,
  .auth .auth-container .auth-header {
    margin: 1rem 0;
  }
}

.auth .auth-container .auth-bb-logo {
  height: 1.5rem;
  margin: 1rem 0;
}

@media screen and (min-width: 900px) {
  .auth .auth-container .auth-bb-logo {
    margin: 0;
  }
}

.ui.form input:not([type]):focus,
.ui.form input[type="date"]:focus,
.ui.form input[type="datetime-local"]:focus,
.ui.form input[type="email"]:focus,
.ui.form input[type="file"]:focus,
.ui.form input[type="number"]:focus,
.ui.form input[type="password"]:focus,
.ui.form input[type="search"]:focus,
.ui.form input[type="tel"]:focus,
.ui.form input[type="text"]:focus,
.ui.form input[type="time"]:focus,
.ui.form input[type="url"]:focus,
.ui.form textarea:focus,
.ui.form select:focus {
  color: rgba(0, 0, 0, 0.95);
  border-color: #6772e5;
  border-radius: 0.28571429rem;
  background: #fff;
  box-shadow: inset 0 0 0 0 rgba(34, 36, 38, 0.35);
}

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  content: "\e800";
}

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  opacity: 1;
  color: rgba(0, 0, 0, 0.95);
}

.ui.checkbox .box:after,
.ui.checkbox label:after {
  font-family: Checkbox;
}

.ui.checkbox .box:after,
.ui.checkbox label:after {
  font-size: 14px;
  text-align: center;
  opacity: 0;
  color: #354052;
}

.auth2 .auth-container .auth-content .auth-form input {
  height: 1.8rem;
  padding: 0.4em 0.4em;
}
.login-content2 .login-userpass {
  width: 100%;
}
.auth2 .auth-container .auth-content .auth-content-header {
  padding: 0 0 1.6rem;
}
.auth2 .form-group {
  margin-bottom: 0rem;
}
.auth2 .invalid-feedback {
  font-size: 70% !important;
}
.ui.input > .bootstrap-select > .dropdown-menu {
  max-width: 100%;
}
.auth2 .auth-container .auth-content .auth-form input[role="combobox"] {
  width: 100%;
}
