@import "../scss/app.scss";
@import "../scss/main.scss";

.active2 .fa {
  color: #121212;
  text-shadow: 0 0 10px rgba(22, 199, 255, 0.5);
}
/*.span3 .sidebar-menu ul li:hover > a,
.span3 .sidebar-menu ul li.active > a {
  color: #FFF;
}*/
.sidebar-dropdown .sidebar-submenu li a:hover:before,
.span3 .sidebar-menu .sidebar-dropdown.active a i,
.span3 .sidebar-search input.search-menu:focus + span,
.span3 ul li:hover a i,
.sidebar-dropdown .sidebar-submenu li.active a:before {
  color: #121212;
  text-shadow: 0 0 10px rgba(22, 199, 255, 0.5);
}

.min-w-150 {
  min-width: 150px;
}

.navbar-nav .nav-link:focus {
  color: #00d0ba !important;
}
.min-w-100 {
  min-width: 100px;
}

.min-w-300 {
  min-width: 300px !important;
}

#client_table > thead {
  display: none !important;
}

.table-fixed {
  table-layout: fixed;
}

.text-70 {
  font-size: 70% !important;
}

.actionLink:hover {
  color: blue;
}

.card2 {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.02);
  border-radius: 0.35rem;
}

.border-left-primary {
  border-left: 0.25rem solid #4e73df !important;
}

.shadow {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}

.text-gray-300 {
  color: #dddfeb !important;
}

.text-gray-800 {
  color: #5a5c69 !important;
}

.text-xs {
  font-size: 0.7rem;
}

.border-left-success {
  border-left: 0.25rem solid #1cc88a !important;
}

.border-left-danger {
  border-left: 0.25rem solid #dc3545 !important;
}

.border-left-info {
  border-left: 0.25rem solid #17a2b8 !important;
}

.border-left-warning {
  border-left: 0.25rem solid #ffc107 !important;
}

.border-left-secondary {
  border-left: 0.25rem solid #6c757d !important;
}

.pointer {
  cursor: pointer !important;
}

input[type="file"] {
  padding: 5px 10px !important;
  height: 37px;
}

div.dt-buttons {
  margin-right: 10px;
}
