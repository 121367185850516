.navbar-transparent {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
    background-color: initial;
    border: 0;
    box-shadow: none;
}

.navbar-nav .nav-link {
    font-size: .9rem;
    font-family: Open Sans,sans-serif;
    font-weight: 400;
    text-transform: normal;
    letter-spacing: 0;
    -webkit-transition: all .15s linear;
    transition: all .15s linear;
}

@media (min-width: 992px) {
    .navbar-nav .nav-link {
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-radius: .25rem;
    }
}


@media (min-width: 768px) {
    .navbar-transparent .navbar-nav .nav-link {
        color: hsla(0,0%,100%,.95);
    }
}

@media (min-width: 768px) {
    .navbar-transparent .navbar-nav .nav-link:focus, .navbar-transparent .navbar-nav .nav-link:hover {
        color: hsla(0,0%,100%,.65);
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding: 1rem;
    }
    
}


